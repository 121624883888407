import React, { useEffect, useState } from 'react'

function Services () {
    const [services, setServices] = useState([]);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?services`)
            const data = await res.json()
            return setServices(data);
        }
        fetchProducts();

        const fetchContacts = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?contacts`)
            const data = await res.json()
            return setContacts(data);
        }
        fetchContacts()
    }, [])


    return (
        <section id='services' className='services'>
            <div className="contact-menu">
                <div className="main">
                    {contacts.map((contact) => {
                        return (
                            <>
                                <div className="up">
                                    <a href={contact.url_instagram} target='_blank' className="card1">
                                        <i className="fa fa-instagram instagram"></i>
                                    </a>
                                    <a href={contact.url_telegram} target='_blank' className="card2">
                                        <i class="fa fa-telegram facebook"></i>
                                    </a>

                                </div>
                                <div className="down">
                                    <a href={contact.url_whatsapp} target='_blank' className="card3">
                                        <i className="fa fa-whatsapp whatsapp"></i>
                                    </a>
                                </div>
                            </>
                        )
                    })}
                </div>
                <button target='_blank' className="card4 support-menu">
                    <svg xmlns="http://www.w3.org/2000/svg" className='support' viewBox="0 0 512 512"><path d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" /></svg>
                </button>
            </div>

            <div className="container">
                <h2 data-aos="fade-left" className="title"><i className="fa fa-location-arrow"></i> الخدمات</h2>
                <div className="boxs">
                    {services.map((service) => {
                        return (
                            <div key={service.id} className="card">
                                <div className="card-inner">
                                    <div className="card-front">
                                        <div data-aos="fade-up-left" className="box">
                                            <div className="animate"><img src={`https://arabarrows.com/arabarrows/assest/imgs/${service.img_animation}`} alt="" /></div>
                                            <img className='icon' src={`https://arabarrows.com/arabarrows/assest/imgs/${service.img_icon}`} alt="" />
                                            <h3>{service.name}</h3>
                                        </div>
                                    </div>
                                    <div className="card-back">
                                        <p>{service.service_about}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Services
