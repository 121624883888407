import React, { useEffect, useState } from 'react'
import logo from "../assest/logo.png"

function Footer () {
    const [logos, setLogo] = useState([]);
    const [services, setServices] = useState([]);
    const [projects, setProjects] = useState([]);

    // hide contact menu
    document.addEventListener('scroll', () => {
        const targetSection = document.querySelector('#contact-section'); // استبدل 'section-id' بمعرف القسم الخاص بك
        const menu = document.querySelector('.services .contact-menu');

        // احسب الموقع الحالي للقسم الذي تريد الوصول إليه
        const targetSectionOffset = targetSection.offsetTop;
        // احسب موقع مستوى التمرير الحالي
        const scrollY = window.scrollY;

        if (scrollY >= targetSectionOffset) {
            menu.style.display = 'none';
        } else {
            menu.style.display = 'block';
        }
    });
    useEffect(() => {
        // fetch logo
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?logo`)
            const data = await res.json()
            return setLogo(data);
        }
        fetchLogo()

        // fetch services
        const fetchServices = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?services`)
            const data = await res.json()
            return setServices(data);
        }
        fetchServices()

        // fetch projects
        const fetchProjects = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?projects`)
            const data = await res.json()
            return setProjects(data);
        }
        fetchProjects()
    }, [])
    return (
        <div data-aos="fade-left"
            data-aos-duration="3000" className='footer'>
            <div className="boxs">
                <div className="box">
                    <h3>الخدمات</h3>
                    <ul className='links-footer'>
                        {services.map((service) => {
                            return (
                                <li key={service.id}><a href="#services">{service.name}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div className="box">
                    <h3>المشاريع</h3>
                    <ul className='links-footer'>
                        {projects.map((project) => {
                            return (
                                <li key={project.id}><a href="#projects">{project.name}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div className="box">
                    <img style={{ width: "15rem" }} src={logos.length > 0 ? `https://arabarrows.com/arabarrows/assest/imgs/${logos[0].logo}` : logo} alt="" />

                    <ul className='links-footer social'>
                        <li className='facebook'><a target='_blank' href="https://www.facebook.com/100063964267007"><i class="fa fa-facebook-f facebook"></i></a></li>
                        <li className='instagram'><a target='_blank' href="https://instagram.com/aa_contracting"><i class="fa fa-instagram instagram"></i></a></li>
                        <li className='whatsapp'><a target='_blank' href="https://wa.me/+97430335833"><i class="fa fa-whatsapp whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
            <div className="copyright">© 2023 <span>سهام العرب</span> All Rights Reserved</div>
        </div>
    )
}

export default Footer
