import React, { useEffect } from 'react'
import bg from '../assest/bg.jpg';
import bgcircle from '../assest/bg-circle.png';
import bgFixed from '../assest/bg-fixed.jpg';
import Aos from 'aos';
import "aos/dist/aos.css"

function Home () {
    setTimeout(() => {
        if (!sessionStorage.getItem('loading')) {
            const loader = document.querySelector('.loading');
            loader.classList.add('none');
            document.documentElement.style.overflow = 'auto';
            sessionStorage.setItem('loading', 1);
        }
    }, 7000);
    if (sessionStorage.getItem('loading') == 1) {
        setTimeout(() => {
            const loader = document.querySelector('.loading');
            loader.classList.add('none');
            document.documentElement.style.overflow = 'auto';
        }, 0)
    }
    setTimeout(() => {
        // menu click
        const menu = document.querySelector('.menu');
        const span1 = document.querySelector('.menu .span1');
        const span2 = document.querySelector('.menu .span2');
        const span3 = document.querySelector('.menu .span3');
        const links = document.querySelector('.links');
        const navbar = document.querySelector('.navbar');

        menu.addEventListener('click', () => {
            links.classList.toggle('active');
            navbar.classList.toggle('active');
            span1.classList.toggle('active');
            span2.classList.toggle('active');
            span3.classList.toggle('active');
        })
        window.onscroll = () => {
            if (links.classList.contains("active")) {
                links.classList.toggle('active');
                navbar.classList.toggle('active');
                span1.classList.toggle('active');
                span2.classList.toggle('active');
                span3.classList.toggle('active');
            }
        }

        const support = document.querySelector('.support-menu');
        const social = document.querySelector('.contact-menu .main');
        support.addEventListener("click", () => {
            social.classList.toggle("active")
        }, 3000)
    })
    useEffect(() => {
        Aos.init();
        const home = document.querySelector(".home")
        home.addEventListener('mousemove', (e) => {
            const cursor = document.querySelector('.cursor');
            cursor.style.left = (e.pageX - 50) + 'px';
            cursor.style.top = (e.pageY - 50) + 'px';
        });

        const imageContainer = document.querySelector('.image-container');
        const image = document.querySelector('.circle');

        imageContainer.addEventListener('mousemove', (e) => {
            const xAxis = (window.innerWidth / 2 - e.pageX) / 20;
            const yAxis = (window.innerHeight / 2 - e.pageY) / 20;

            image.style.transform = `translateY(${xAxis}px) translateX(${yAxis}px)`;
        });

        // إعادة تعيين تأثير التحول عند مغادرة الماوس
        imageContainer.addEventListener('mouseleave', () => {
            image.style.transform = 'rotateY(0deg) rotateX(0deg)';
        });

        const cursor = document.querySelector('.cursor');

        document.addEventListener('click', e => {
            cursor.classList.add("expand");
            setTimeout(() => {
                cursor.classList.remove("expand");
            }, 500);
        });
    })



    return (
        <section className='home' id='home'>
            <div className="bg-fixed"><img src={bgFixed} alt="" /></div>
            <div className="loading">
                <div class="loader">
                    <div class="box box-1">
                        <div class="side-left"></div>
                        <div class="side-right"></div>
                        <div class="side-top"></div>
                    </div>
                    <div class="box box-2">
                        <div class="side-left"></div>
                        <div class="side-right"></div>
                        <div class="side-top"></div>
                    </div>
                    <div class="box box-3">
                        <div class="side-left"></div>
                        <div class="side-right"></div>
                        <div class="side-top"></div>
                    </div>
                    <div class="box box-4">
                        <div class="side-left"></div>
                        <div class="side-right"></div>
                        <div class="side-top"></div>
                    </div>
                </div>
                <h2>اهلا ومرحباً بكم في سهام العرب للمقاولات والديكور</h2>

            </div>

            <div className="cursor"></div>
            <img className="bg" src={bg} alt="" />
            <div className="container">
                <div data-aos="zoom-out" data-aos-duration="1000" className="content">
                    <img className='bg-circle' src={`https://arabarrows.com/arabarrows/assest/imgs/bg-circle.png`} alt="" />
                    <div className="text">
                        <h2 data-aos="fade-left" data-aos-duration="2000">مرحباً بكم في <span>سهام العرب</span></h2>
                        <p data-aos="fade-left" data-aos-duration="3000">هل تبحث عن شركة يمكنك الاعتماد عليها لتحويل منزلك أو مكان عملك إلى تحفة فنية؟ إذًا لا تبحث بعيدًا، فشركة <span>سهام العرب</span> هي الإجابة التي تبحث عنها!
                            مع <span>سهام العرب</span> ، يتحقق حلمك بتصميم وتنفيذ مشروع الديكور الذي ترغب فيه. نحن نقدم خدمات المقاولات والديكور بأعلى جودة، باستخدام أفضل المواد والتصاميم المبتكرة...</p>
                    </div>
                    <div className="search">

                        <button className='btn'><a style={{ color: 'var(--gray)' }} href="#projects">مشاريعنا</a> <i className="fa fa-building icon-hover"></i></button>
                    </div>
                </div>
                <div className="image-container">
                    <img className='circle' src={`https://arabarrows.com/arabarrows/assest/imgs/bg-circle.png`} alt="" />
                </div>
            </div>
        </section>
    )
}

export default Home
