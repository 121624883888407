import React, { useEffect, useRef, useState } from 'react'

function Projects () {
    const total30 = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
    const [projects, setProjects] = useState([]);
    const [activeCard, setActiveCard] = useState(null);
    const [showCardBack, setShowCardBack] = useState(false);
    const [sectionKey, setSectionKey] = useState(0);

    // Use a ref to store the container for updates
    const sectionRef = useRef();
    // Function to reload the section
    const reloadSection = () => {
        setSectionKey((prevKey) => prevKey + 1);
    };

    // fetch projects
    const fetchProducts = async () => {
        const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?projects`)
        const data = await res.json()
        return setProjects(data);
    }


    useEffect(() => {
        return () => {
            // expand photos
            const cardContainer = document.querySelectorAll(".projects .cards .card");
            const overly = document.querySelector(".projects .overly");
            const overlyContent = document.querySelector(".projects .overly .content-overly");
            const exit = document.querySelector('.exit');

            cardContainer.forEach((card) => {
                card.addEventListener("click", (event) => {
                    console.log('yes');
                    document.body.style.overflow = "hidden";
                    overly.classList.add("active");
                    exit.style.display = 'block';
                    const backCard = event.currentTarget.querySelector('.card-back');
                    backCard.classList.add("active");
                    overlyContent.appendChild(backCard);
                });
            });

            // exit photos
            exit.addEventListener("click", () => {
                document.body.style.overflow = "visible";
                overly.classList.remove("active");
                exit.style.display = 'none';
                const back = document.querySelectorAll('.card-back');
                back.forEach((card) => {
                    card.classList.remove('active');
                })

                reloadSection();
            });

        }
    }, [fetchProducts])


    useEffect(() => {
        fetchProducts()
    }, [])



    // next and prev
    let index = 0;

    // arrows1
    function leftArrow (sum) {
        let slides = document.querySelectorAll(`.sum${sum} .testimonials-item`);
        console.log(index);
        slides[index].classList.remove('active');
        index = (index + 1) % slides.length;
        slides[index].classList.add('active');
    }

    function rightArrow (sum) {
        let slides = document.querySelectorAll(`.sum${sum} .testimonials-item`);

        slides[index].classList.remove('active');
        index = (index - 1 + slides.length) % slides.length;
        slides[index].classList.add('active');
    }

    return (
        <section key={sectionKey} ref={sectionRef} id='projects' className='projects'>
            {/* <div className="cursor-projects" data-message="شاهد"></div> */}
            <div className="container">
                <h2 data-aos="fade-left" className="title"><i class="fa fa-location-arrow"></i> المشاريع</h2>
                <div className="cards">
                    {projects.map((project, sum) => {
                        return (
                            <div key={project.id} data-aos="zoom-in-up" class={`card`}>
                                <div class="card-inner">
                                    <div class="card-front">
                                        <img src={`https://arabarrows.com/arabarrows/assest/imgs/${project.img}`} style={{ width: "25rem", maxHeight: "16rem" }} alt="" />
                                        <h3>{project.name}</h3>
                                    </div>
                                    <div class={`card-back sum${sum}`}>
                                        <div class="testimonials-item active">
                                            <img src={`https://arabarrows.com/arabarrows/assest/imgs/${project.img_project1}`} alt="" />
                                            <h1>{project.caption_img_project1}</h1>
                                        </div>
                                        {
                                            total30.map((number) => {
                                                const imgKey = `img_project${number}`;
                                                const captionKey = `caption_img_project${number}`;

                                                if (project[imgKey] && project[imgKey].length > 4) {
                                                    return (
                                                        <div key={number} className="testimonials-item">
                                                            <img src={`https://arabarrows.com/arabarrows/assest/imgs/${project[imgKey]}`} alt="" />
                                                            <h1>{project[captionKey]}</h1>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }


                                        <div class="left-arrow" onClick={() => leftArrow(sum)}><i class="bx bxs-left-arrow-alt"></i>التالي</div>
                                        <div class="right-arrow" onClick={() => rightArrow(sum)}><i class="bx bxs-right-arrow-alt"></i>السابق</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className="overly">
                    <span className='exit'>
                        <span className='span2 active'></span>
                        <span className='span3 active'></span>
                    </span>
                    <div className="content-overly"></div></div>
            </div>
        </section>
    )
}

export default Projects
