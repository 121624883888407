import React, { useEffect, useState } from 'react'
import logo from "../assest/logo.png"

function Navbar () {
    const [color, setColor] = useState([]);
    const [logos, setLogo] = useState([]);
    const [visitor, setVisitor] = useState(0);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }
    useEffect(() => {
        // fetch color
        const fetchColor = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?color`)
            const data = await res.json()
            return setColor(data);
        }
        fetchColor()
        // fetch logo
        const fetchLogo = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?logo`)
            const data = await res.json()
            return setLogo(data);
        }
        fetchLogo()
        // fetch visitor
        const fetchVisitor = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?visitor`)
            const data = await res.json()
            return setVisitor(data[0].total);
        }
        fetchVisitor()
    }, [])

    if (visitor > 0) {
        if (!sessionStorage.getItem('visit')) {
            // add visitor
            const addVisitor = () => {
                fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?visitor=${visitor}`, { method: 'POST' })
            }
            sessionStorage.setItem('visit', 1)
            addVisitor()
        }
    }

    // active links2
    document.addEventListener('scroll', () => {
        const HomeSection = document.querySelector('.home'); // استبدل 'section-id' بمعرف القسم الخاص بك
        const ServicesSection = document.querySelector('.services'); // استبدل 'section-id' بمعرف القسم الخاص بك
        const ProjectsSection = document.querySelector('.projects'); // استبدل 'section-id' بمعرف القسم الخاص بك
        const AboutSection = document.querySelector('.about'); // استبدل 'section-id' بمعرف القسم الخاص بك
        const ContactSection = document.querySelector('.contact-section'); // استبدل 'section-id' بمعرف القسم الخاص بك

        // احسب الموقع الحالي للقسم الذي تريد الوصول إليه
        const HomeSectionOffset = HomeSection.offsetTop;
        const ServicesSectionOffset = ServicesSection.offsetTop;
        const ProjectsSectionOffset = ProjectsSection.offsetTop;
        const AboutSectionOffset = AboutSection.offsetTop;
        const ContactSectionOffset = ContactSection.offsetTop;

        // احسب موقع مستوى التمرير الحالي
        const scrollY = window.scrollY;

        // link click
        const AlLinks = document.querySelectorAll('.links li');
        if (scrollY >= HomeSectionOffset && scrollY < ServicesSectionOffset) {
            AlLinks.forEach((li) => li.classList.remove("active"));
            document.querySelector(".links .home-link").classList.add("active")

        } else if (scrollY >= ServicesSectionOffset && scrollY < ProjectsSectionOffset) {
            AlLinks.forEach((li) => li.classList.remove("active"));
            document.querySelector(".links .services-link").classList.add("active")

        } else if (scrollY >= ProjectsSectionOffset && scrollY < AboutSectionOffset) {
            AlLinks.forEach((li) => li.classList.remove("active"));
            document.querySelector(".links .projects-link").classList.add("active")

        } else if (scrollY >= AboutSectionOffset && scrollY < ContactSectionOffset) {
            AlLinks.forEach((li) => li.classList.remove("active"));
            document.querySelector(".links .about-link").classList.add("active")

        } else if (scrollY >= ContactSectionOffset) {
            AlLinks.forEach((li) => li.classList.remove("active"));
        }

    });

    return (
        <div className='navbar'>
            <h2 className="logo"><a href="#home"><img id='logo' src={logos.length > 0 ? `https://arabarrows.com/arabarrows/assest/imgs/${logos[0].logo}` : logo} alt="" /></a></h2>
            <div className="links">
                <ul>
                    <li className='home-link active'><a href="#home">الرئيسية</a></li>
                    <li className='services-link'><a href="#services">الخدمات</a></li>
                    <li className='projects-link'><a href="#projects">المشاريع</a></li>
                    <li className='about-link'><a href="#about">من نحن</a></li>
                    <li className='about-link'><a href="#about" id='google_translate_element'> </a></li>
                    <button className='contact-mobile'>
                        <a href="#contact-section" className="contact"><span className="text">إتصل بنا</span></a>
                    </button>
                </ul>
            </div>

            <button className='contact-pc'>
                <a href="#contact-section" className="contact"><span className="text">إتصل بنا</span></a>
            </button>
            <div className='menu'>
                <span className='span1'></span>
                <span className='span2'></span>
                <span className='span3'></span>
            </div>
        </div>
    )
}

export default Navbar
