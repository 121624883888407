import React, { useEffect, useState } from 'react'

function Contact () {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        const fetchContacts = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?contacts`)
            const data = await res.json()
            return setContacts(data);
        }
        fetchContacts()
    }, [])
    return (
        <section className='contact-section' id='contact-section'>
            <div className="container">
                <h2 data-aos="fade-left" className="title"><i class="fa fa-location-arrow"></i> تواصل معنا</h2>
                <div className="boxs">
                    {contacts.map((contact) => {
                        return (
                            <>
                                <div data-aos="flip-down" data-aos-duration="2000" className="social">
                                    <a href={contact.url_facebook} target='_blank' class="card">
                                        <div class="bg">
                                            <i class="fa fa-facebook-f facebook"></i>
                                        </div>
                                        <div class="blob one"></div>
                                    </a>
                                    <a href={contact.url_instagram} target='_blank' class="card">
                                        <div class="bg">
                                            <i class="fa fa-instagram instagram"></i>
                                        </div>
                                        <div class="blob two"></div>
                                    </a>
                                    <a href={contact.url_whatsapp} target='_blank' class="card">
                                        <div class="bg">
                                            <i class="fa fa-whatsapp whatsapp"></i>
                                        </div>
                                        <div class="blob three"></div>
                                    </a>
                                    <a href={contact.url_telegram} target='_blank' class="card">
                                        <div class="bg">
                                            <i class="fa fa-telegram telegram"></i>
                                        </div>
                                        <div class="blob four"></div>
                                    </a>
                                </div>
                                <div data-aos="fade-up"
                                    data-aos-duration="3000" className="maps">
                                    <div className="map"><iframe src={contact.url_map_one} style={{ width: "", height: "" }}></iframe></div>
                                    {contact.url_map_two.length > 1 ? <div className="map"><iframe src={contact.url_map_two} style={{ width: "", height: "" }}></iframe></div> : null}
                                    {contact.url_map_three.length > 1 ? <div className="map"><iframe src={contact.url_map_three} style={{ width: "", height: "" }}></iframe></div> : null}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}



export default Contact
