import React, { useEffect, useState } from 'react';
import icon1 from "../assest/about-icon1.png"
import icon2 from "../assest/about-icon2.png"
import icon3 from "../assest/about-icon3.png"

function About () {
    const [abouts, setAbout] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}s_d.php?about`)
            const data = await res.json()
            return setAbout(data);
        }
        fetchProducts()
    }, [])

    return (
        <section className='about' id='about'>
            <div className="container">
                <h2 data-aos="fade-left" className="title"><i class="fa fa-location-arrow"></i> من نحن</h2>
                {abouts.map((info) => {
                    return (
                        <>
                            <div className="box-container">
                                <div data-aos="zoom-in-left" data-aos-offset="300" data-aos-easing="ease-in-sine" className="content">
                                    <img style={{ width: "10rem" }} src={`https://arabarrows.com/arabarrows/assest/imgs/${info.img}`} alt="" />
                                    <h3 className='heading'>سهام العرب</h3>
                                    <p>{info.information}</p>
                                    <button><a href="#contact-section">تواصل معنا</a></button>
                                </div>
                            </div>
                            <div className="status">
                                <div data-aos="fade-up" data-aos-duration="2000" className="state">
                                    <img src={icon1} alt="" />
                                    <h3>{info.total_projects}</h3>
                                    <p>المشاريع</p>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" className="state">
                                    <img src={icon2} alt="" />
                                    <h3>{info.total_customers}</h3>
                                    <p>العملاء</p>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="2000" className="state">
                                    <img src={icon3} alt="" />
                                    <h3>{info.total_employees}</h3>
                                    <p>موظف</p>
                                </div>

                            </div>
                        </>
                    )
                })}
            </div>
        </section>
    )
}

export default About
